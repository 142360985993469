<template>
  <div class="d-flex align-center">
    <v-btn fab small @click="decrement"><ph-minus :size="24" /></v-btn>
    <v-text-field
      hide-details="true"
      :height="$vuetify.breakpoint.smAndDown?88:55"
      class="mx-4 text-center"
      :class="[$vuetify.breakpoint.smAndDown?'pt-2':'pt-0']"
      :value="localValue"
      @input="change"
      type="number"
    ></v-text-field>
    <v-btn fab small @click="increment"><ph-plus :size="24" /></v-btn>
  </div>
</template>

<script>
import { PhMinus, PhPlus } from "phosphor-vue";
export default {
  name: "IncrementComponent",
  data() {
    return {
      localValue: 0,
    };
  },
  components: {
    PhMinus,
    PhPlus,
  },
  props: {
    value: {
      type: [Number, String],
      default: "0.00",
    },
  },
  methods: {
    increment() {
      this.localValue = parseFloat(this.localValue) + 1;

      this.$emit("change", this.localValue);
    },
    decrement() {
      if (this.localValue < 1) {
        return;
      }
      this.localValue = parseFloat(this.localValue) - 1;
      this.$emit("change", this.localValue);
    },
    change(val) {
      if (parseFloat(this.localValue) < 0) {
        return;
      }
      this.localValue = parseFloat(val);
      this.$emit("change", val);
    },
  },
  mounted() {
    this.localValue = JSON.parse(JSON.stringify(this.value));
    this.$el.querySelectorAll('input')[0].setAttribute('inputmode','decimal')
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-text-field__slot > input {
  text-align: center;
  color: var(--v-secondary-base);
  font-size: 40px;
  padding-top: 6px;
  max-height:80px;
  height:80px;
  //   margin-top:15px;
}
</style>
